const locales = {
  en: {
    translation: {
      joinMeeting: 'Join Meeting',
      guestFieldsInfo: 'To join the meeting you need to enter your name and the company you belong to.',
      fullName: 'Full name',
      company: 'Company',
      clickToJoin: 'Click here to join this meeting',
      mute: 'Mute',
      unmute: 'Unmute',
      stopVideo: 'Stop Video',
      startVideo: 'Start Video',
      audioVideoSettings: 'Audio and Video Settings',
      video: 'Video',
      videoInput: 'Video Input',
      noLocalVideo: 'No Local Video',
      audio: 'Audio',
      audioInput: 'Audio Input',
      audioOutput: 'Audio Output',
      testAudioOutput: 'Test Audio Output',
      stop: 'Stop',
      done: 'Done',
      joiningMeeting: 'Joining Meeting',
      you: ' (You)',
      shareScreen: 'Share Screen',
      addGuest: 'Add Guest',
      inviteGuestInfo: 'To invite someone to this meeting, share this link with them.',
      copyInvitation: 'Copy Invitation',
      disconnect: 'Disconnect',
      more: 'More',
      fullScreen: 'Full Screen',
      backgrounds: 'Backgrounds',
      stopSharingScreen: 'Stop Sharing Screen',
      anotherSharingScreen: 'Cannot share screen when another user is sharing',
      screenShareNotSupported: 'Screen sharing is not supported with this browser',
      exitFullScreen: 'Exit full screen',
      noAudio: 'No Audio',
      noVideo: 'No Video',
      flipCamera: 'Flip camera',
      reconnecting: 'Reconnecting...',
      defaultAudioOutput: 'System Default Audio Output',
      screen: ' - Screen',
      noiseCancellationPoweredBy: 'Noise Cancellation powered by',
      suppressBackgroundNoise: 'Suppress background noise from your microphone',
      enabled: 'Enabled',
      disabled: 'Disabled',
      galleryView: 'Gallery View',
      speakerView: 'Speaker View',
      maxGalleryViewParticipants: 'Max Gallery View Participants',
      roomMonitor: 'Room Monitor',
      unsupportedBrowser: {
        title: 'Browser or context not supported',
        pleaseOpenInSupported: 'Please open this application in one of the <1>supported browsers</1>.',
        useSecureConnection:
          'If you are using a supported browser, please ensure that this app is served over a <1>secure context</1> (e.g. https or localhost).',
      },
    },
  },
  es: {
    translation: {
      joinMeeting: 'Unirse a la Reunión',
      guestFieldsInfo: 'Para unirse a la reunión, por favor ingresar nombre y organización a la cual pertenece.',
      fullName: 'Nombre completo',
      company: 'Organización',
      clickToJoin: 'Haz clic aquí para unirte a la reunión',
      mute: 'Silenciar',
      unmute: 'Activar sonido',
      stopVideo: 'Desactivar video',
      startVideo: 'Activar video',
      audioVideoSettings: 'Configuración de audio y video',
      video: 'Video',
      videoInput: 'Entrada de video',
      noLocalVideo: 'No existe video local',
      audio: 'Audio',
      audioInput: 'Entrada de audio',
      audioOutput: 'Salida de audio',
      testAudioOutput: 'Probar salida de audio',
      stop: 'Detener',
      done: 'Listo',
      joiningMeeting: 'Uniéndose a la reunión',
      you: ' (Tú)',
      shareScreen: 'Compartir pantalla',
      addGuest: 'Agregar invitado',
      inviteGuestInfo: 'Para invitar a alguien a esta reunión, comparte el siguiente link con ellos.',
      copyInvitation: 'Copiar invitación',
      disconnect: 'Desconectarse',
      more: 'Más',
      fullScreen: 'Pantalla completa',
      backgrounds: 'Fondos de Pantalla',
      stopSharingScreen: 'Detener compartir pantalla',
      anotherSharingScreen: 'No es posible compartir pantalla mientras otro participante está compartiendo',
      screenShareNotSupported: 'Compartir pantalla no está disponible para el navegador que estás usando',
      exitFullScreen: 'Salir de pantalla completa',
      noAudio: 'Sin audio',
      noVideo: 'Sin video',
      flipCamera: 'Rotar camara',
      reconnecting: 'Volviendo a conectar…',
      defaultAudioOutput: 'Salida de audio por defecto del sistema',
      screen: ' - Pantalla',
      noiseCancellationPoweredBy: 'Cancelación de ruido desarrollada por',
      suppressBackgroundNoise: 'Suprime el ruido de fondo de tu micrófono',
      enabled: 'Habilitado',
      disabled: 'Deshabilitado',
      galleryView: 'Vista de galería',
      speakerView: 'Vista de orador',
      maxGalleryViewParticipants: 'Máximo de participantes en la vista de galería',
      roomMonitor: 'Monitor de la sala',
      unsupportedBrowser: {
        title: 'El navegador o el contexto no son compatibles',
        pleaseOpenInSupported: 'Abra esta aplicación en uno de los <1>navegadores compatibles</1>.',
        useSecureConnection:
          'Si utiliza un navegador compatible, asegúrese de que esta aplicación se muestre en un <1>contexto seguro</1> (por ejemplo, https o localhost).',
      },
    },
  },
  fr: {
    translation: {
      joinMeeting: 'Rejoindre la réunion',
      guestFieldsInfo:
        'Pour participer à la réunion, vous devez indiquer votre nom et la société à laquelle vous appartenez.',
      fullName: 'Nom complet',
      company: 'Entreprise',
      clickToJoin: 'Cliquez ici pour rejoindre cette réunion',
      mute: 'Silencieux',
      unmute: 'Démutez',
      stopVideo: 'Arrêtez la vidéo',
      startVideo: 'Démarrer la vidéo',
      audioVideoSettings: 'Paramètres audio et vidéo',
      video: 'Video',
      videoInput: 'Entrée vidéo',
      noLocalVideo: 'Pas de vidéo locale',
      audio: 'Audio',
      audioInput: 'Entrée audio',
      audioOutput: 'Sortie audio',
      testAudioOutput: 'Test de la sortie audio',
      stop: 'Arrêtez',
      done: 'Terminé',
      joiningMeeting: 'Rejoindre la réunion',
      you: ' (Toi)',
      shareScreen: 'Écran de partage',
      addGuest: 'Ajouter un invité',
      inviteGuestInfo: "Pour inviter quelqu'un à cette réunion, partagez ce lien avec lui",
      copyInvitation: "Copie de l'invitation",
      disconnect: 'Déconnexion',
      more: 'Plus',
      fullScreen: 'Plein écran',
      backgrounds: "Fonds d'écran",
      stopSharingScreen: "Arrêter de partager l'écran",
      anotherSharingScreen: "Impossible de partager l'écran lorsqu'un autre utilisateur le fait ",
      screenShareNotSupported: "Le partage d'écran n'est pas pris en charge par ce navigateur",
      exitFullScreen: 'Quitter le plein écran',
      noAudio: 'Sans audio',
      noVideo: 'Sans video',
      flipCamera: 'Retourner la caméra',
      reconnecting: 'Se reconnecter',
      defaultAudioOutput: 'Sortie audio par défaut du système',
      screen: ' - Écran',
      noiseCancellationPoweredBy: 'Suppression du bruit alimentée par',
      suppressBackgroundNoise: 'Supprimez le bruit de fond de votre microphone',
      enabled: 'Activé',
      disabled: 'Désactivé',
      galleryView: 'Affichage de la galerie',
      speakerView: "Vue de l'intervenant",
      maxGalleryViewParticipants: 'Nombre maximum de participants dans la galerie',
      roomMonitor: 'Moniteur de salle',
      unsupportedBrowser: {
        title: 'Navigateur ou contexte non pris en charge',
        pleaseOpenInSupported: "Ouvrez cette application dans l'un des <1>navigateurs compatibles</1>.",
        useSecureConnection:
          'Si vous utilisez un navigateur compatible, assurez-vous que cette application est diffusée dans un <1>contexte sécurisé</1> (par exemple https ou localhost).',
      },
    },
  },
  pt: {
    translation: {
      joinMeeting: 'Junte-se ao reunião',
      guestFieldsInfo: 'Para participar da reunião, você precisa inserir seu nome e a empresa a que pertence.',
      fullName: 'Nome completo',
      company: 'Empresa',
      clickToJoin: 'Clique aqui para participar desta reunião',
      mute: 'Silêncio',
      unmute: 'Ativar som',
      stopVideo: 'Desativar vídeo',
      startVideo: 'Ativar vídeo',
      audioVideoSettings: 'Configurações de áudio e vídeo',
      video: 'Vídeo',
      videoInput: 'Entrada de vídeo',
      noLocalVideo: 'Não há nenhum vídeo local',
      audio: 'Áudio',
      audioInput: 'Entrada de áudio',
      audioOutput: 'Saída de áudio',
      testAudioOutput: 'Teste de saída de áudio',
      stop: 'Pare',
      done: 'Feito',
      joiningMeeting: 'Entrando na reunião',
      you: ' (Vocês)',
      shareScreen: 'Compartilhar tela',
      addGuest: 'Adicionar convidado',
      inviteGuestInfo: 'Para convidar alguém para esta reunião, compartilhe este link com essa pessoa.',
      copyInvitation: 'Copiar Convite',
      disconnect: 'Desconectar',
      more: 'Mais',
      fullScreen: 'Tela cheia',
      backgrounds: 'Fundos',
      stopSharingScreen: 'Parar de compartilhar a tela',
      anotherSharingScreen: 'Não é possível compartilhar a tela quando outro usuário está compartilhando',
      screenShareNotSupported: 'O compartilhamento de tela não é compatível com este navegador',
      exitFullScreen: 'Sair da tela inteira',
      noAudio: 'Sem áudio',
      noVideo: 'Sem vídeo',
      flipCamera: 'Girar câmera',
      reconnecting: 'Reconectando …',
      defaultAudioOutput: 'Saída áudio por defeito do sistema',
      screen: ' - Tela',
      noiseCancellationPoweredBy: 'Cancelamento de ruído alimentado por',
      suppressBackgroundNoise: 'Suprima o ruído de fundo do seu microfone',
      enabled: 'Habilitado',
      disabled: 'Desabilitado',
      galleryView: 'Visualização da Galeria',
      speakerView: 'Visualização do alto-falante',
      maxGalleryViewParticipants: 'Participantes máximos da exibição da galeria',
      roomMonitor: 'Monitor de Sala',
      unsupportedBrowser: {
        title: 'Navegador ou contexto não suportados',
        pleaseOpenInSupported: 'Abra este aplicativo em um dos <1>navegadores compatíveis</1>.',
        useSecureConnection:
          'Se você estiver usando um navegador compatível, certifique-se de que este aplicativo seja servido em um <1>contexto seguro</1> (por exemplo, https ou localhost).',
      },
    },
  },
};

export default locales;
